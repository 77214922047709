"use strict";

import './common/isnan.js';
import $ from 'jquery';

//stickyfill
// import Stickyfill from './common/stickyfill.es6.js';

//ofl
// import objectFitImages from 'object-fit-images';

// SLICK
import slick from 'slick-carousel/slick/slick.min.js';
import 'slick-carousel/slick/slick.scss';

//desvg
// import 'desvg';


// // SWIPER (ver.4)
// import Swiper from 'swiper/dist/js/swiper.min.js';
// import 'swiper/dist/css/swiper.min.css';

// SCROLL MAGIC & GSAP
import gsap, {
  TweenMax,
  TimelineMax,
  Linear,
  Power1,
  Expo
} from "gsap";
import * as ScrollMagic from "scrollmagic";
import {
  ScrollMagicPluginGsap
} from "scrollmagic-plugin-gsap";
gsap.registerPlugin();
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

// import './common/debug.addIndicators';
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

// import kvlogo01 from "../img/top/logo_kv01.svg";
// import kvlogo02 from "../img/top/logo_kv02.svg";
// import kvlogo03 from "../img/top/logo_kv03.svg";
// import kvlogo04 from "../img/top/logo_kv04.svg";
// import kvlogo05 from "../img/top/logo_kv05.svg";
// import kvlogo06 from "../img/top/logo_kv06.svg";
// import kvlogo07 from "../img/top/logo_kv07.svg";
// import kvlogo08 from "../img/top/logo_kv08.svg";
// import kvlogo09 from "../img/top/logo_kv09.svg";

const kvLogos = [
  "./assets/img/top/logo_kv01.svg",
  "./assets/img/top/logo_kv02.svg",
  "./assets/img/top/logo_kv03.svg",
  "./assets/img/top/logo_kv04.svg",
  "./assets/img/top/logo_kv05.svg",
  "./assets/img/top/logo_kv06.svg",
  "./assets/img/top/logo_kv07.svg",
  "./assets/img/top/logo_kv08.svg",
  "./assets/img/top/logo_kv09.svg",
]

let fnc;

// サイズ設定
const breakpoint = 768;
const pcsize = 1280;
const spsize = 375;
const maxwidth = 1280;
const sizeRatio = pcsize / spsize;


class Fnc {
  constructor() {
    this.device = (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) ? 'sp' : 'pc';
    this.ie = (navigator.userAgent.toLowerCase().indexOf('msie') != -1 || navigator.userAgent.toLowerCase().indexOf('trident') != -1) ? true : false;
    // this.page_id = $('main').attr('id'); //ページID
    this.page_id = $('body').attr('id'); //ページID
    this.distance = 0; //スクロール距離
    this.oldDistance = 0; //nav open時のスクロール距離
    this.window = {
      w: window.innerWidth,
      h: window.innerHeight,
      bar: $(window).innerWidth() - $(window).width(),
    };

    this.oldWindow = {
      w: 0,
      h: 0,
      bar: 0,
    };

    this.loading;

    this.controller = new ScrollMagic.Controller();

    this.parallaxes = [];
    this.tween = [];
    this.messageScenes = [];
    this.messageTween = [];

    this.eventNum = {
      fadeIn: this.window.h * 0.8,
    }

    this.eventFlag = {
      ticking: false,
      headerOpen: false,
    };
  }

  ready() {
    // this.anchor();
    this.ieSmoothScroll();
    this.timer();
    this.hamburger();
    if (this.page_id == "top") this.topReadyEvent();
  }

  loaded() {
    this.loadedScreen();
    this.parallaxImg();
    if (this.page_id == "top") this.topLoadEvent();
  }

  resize() {
    this.oldWindow.w = this.window.w;
    this.oldWindow.h = this.window.h;
    this.oldWindow.bar = this.window.bar;

    this.window.w = window.innerWidth;
    this.window.h = window.innerHeight;

    this.device = (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) ? 'sp' : 'pc';

    this.hamburger();

    this.controller.updateScene(true);
    this.scrollResizeEvent();
  }

  loadedScreen() {
    $('body').addClass('loaded');

    setTimeout(() => {
      $('.load_bg').remove();
    }, 300);
  }

  //ie用スクロール
  anchor() {
    const t = this;
    // #で始まるアンカーをクリックした場合に処理
    // $('a[href^="#"]').not('a[rel^="modal"]').on('click', function() {
    $('a[href^="#"]').on('click', function() {
      // スクロールの速度
      var speed = 400; // ミリ秒
      // アンカーの値取得
      var href = $(this).attr("href");
      // 移動先を取得
      var targetHref = href == "#" || href == "" ? 'html' : href;
      var target = $(targetHref);
      // 移動先を数値で取得
      let position = target.offset().top;
      // position -= $('header .header').innerHeight() - (fnc.window.h * 0.01);

      // スムーススクロール
      $('body,html').animate({
        scrollTop: position
      }, speed, 'swing');
      return false;
    });
  }

  //ie用スクロール
  ieSmoothScroll() {
    if (this.ie) {
      const ScrollLength = 300;
      const ScrollSpeed = 300;

      let ScrollSet;
      let ticks = false;

      let mousewheelevent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
      document.addEventListener(mousewheelevent, function(e) {
        e.preventDefault();
        if (!ticks) {
          requestAnimationFrame(function() {
            ticks = false;
            let delta = e.deltaY ? -(e.deltaY) : e.wheelDelta ? e.wheelDelta : -(e.detail);
            if (delta < 0) {
              ScrollSet = $(document).scrollTop() + ScrollLength;
            } else {
              ScrollSet = $(document).scrollTop() - ScrollLength;
            }
            $('html,body').stop().animate({
              scrollTop: ScrollSet
            }, ScrollSpeed);
            return false;
          });
          ticks = true;
        }
      }, {
        passive: false
      });
    }
  }

  //ie用イベント
  ieEvent() {
    objectFitImages();
  }

  //スクロールイベント リサイズ時処理
  scrollResizeEvent() {
    if (this.oldWindow.w == this.window.w) return false;
    this.scrollMagicResize();
  }

  //スクロールマジック リサイズ時処理
  scrollMagicResize() {
    const F = this;
    $.each(F.parallaxes, function(index) {
      const t = this;
      let tween = F.tween[index];
      const element = $(this.triggerElement());
      const img = element.find('img');

      const distance = (F.device == "pc") ? "-30%" : "0%";
      tween.vars.y = distance + "rem";

      t.duration(element.outerHeight() + F.window.h);
      t.refresh();
    });

    $.each(F.messageScenes, function(index) {
      const t = this;
      // let tween = F.messageTween[index];
      // const element = $(this.triggerElement());
      // const img = element.find('img');
      // const distance = (F.device == "pc") ? ((233) / spsize * 100 / 3.125 / sizeRatio) : ((-168) / spsize * 100 / 3.125);
      // tween.vars.y = distance + "rem";
      //
      // t.duration(element.outerHeight() + F.window.h);
      t.refresh();
    });
  }

  //スクロールイベント代用
  timer() {
    const F = this;

    setInterval(function() {
      if (!F.eventFlag.ticking) {
        requestAnimationFrame(function() {
          F.eventFlag.ticking = false;
          F.distance = $(document).scrollTop();

          F.fadeIn();

        });
        F.eventFlag.ticking = true;
      }
    }, 24);
  }

  hamburger() {
    const F = this;

    const btn = $('.js-hamburger');
    const nav = $('aside');
    const body = $('body');
    const links = $('a[href^="#"]');

    let count = 0;

    btn.off('click');
    links.off('click');
    nav.find('a[href^="#"]').off('click');


    btn.on('click', function() {
      if (F.eventFlag.headerOpen) {
        $(document).scrollTop(F.oldDistance, 0);
        F.eventFlag.headerOpen = false;
      } else {
        F.oldDistance = F.distance;
        F.eventFlag.headerOpen = true;
      }

      body.toggleClass('open');
      nav.toggleClass('open');
      console.log("success");
    });

    links.on('click', function() {
      // スクロールの速度
      var speed = 400; // ミリ秒
      // アンカーの値取得
      var href = $(this).attr("href");
      // 移動先を取得
      var targetHref = href == "#" || href == "" ? 'html' : href;
      var target = $(targetHref);
      // 移動先を数値で取得
      let position = target.offset().top;
      if (F.window.w < breakpoint) {
        position -= $('header .header').innerHeight() - (F.window.h * 0.01);
      }

      // スムーススクロール
      $('body,html').animate({
        scrollTop: position
      }, speed, 'swing');
      return false;
    });

    if (F.window.w < breakpoint) {
      nav.find('a[href^="#"]').on('click', function() {
        if (F.eventFlag.headerOpen) {
          $(document).scrollTop(F.oldDistance, 0);
          F.eventFlag.headerOpen = false;
        } else {
          F.oldDistance = F.distance;
          F.eventFlag.headerOpen = true;
        }

        body.toggleClass('open');
        nav.toggleClass('open');
      });
    }
  }

  //共通アニメーション
  //フェードイン
  fadeIn() {
    const target = $('.js-fadeIn');
    target.each(function() {
      const t = $(this);
      const top = t.offset().top;

      if (top < fnc.distance + fnc.eventNum.fadeIn) {
        t.addClass('fadeIn');
      }

    });
  }

  parallaxImg() {
    const F = this;
    const target = $('.js-parallax');

    target.each(function() {
      const t = $(this);
      const img = t.find('img');
      let distance = (F.device == "pc") ? "-35%" : "0%";
      if(target.hasClass('parallax01') && F.device == "pc") {
        distance = "-50%";
      }

      const option = {
        y: distance,
        ease: Linear,
      }

      const tween = TweenMax.to(img, 1, option);

      const scene = new ScrollMagic.Scene({
          triggerElement: t[0],
          triggerHook: "onEnter", // トリガーフック指定
          duration: t.outerHeight() + F.window.h,

        })
        .setTween(tween)
        .addTo(F.controller);

      F.messageScenes.push(scene);
      F.messageTween.push(tween);
    });
  }

  // top イベント
  topReadyEvent() {
    this.topSlider();
    this.gallerySlider();
  }

  topLoadEvent() {
    this.messageBg();
  }

  messageBg() {
    const F = this;
    const target = $('.js-messageBg');
    const distance = (F.device == "pc") ? "0" : "0";

    const option = {
      x: distance,
      ease: Linear,
    }

    const tween = TweenMax.to(target, 1, option);

    const scene = new ScrollMagic.Scene({
        triggerElement: target[0],
        triggerHook: "onEnter", // トリガーフック指定
        duration: target.outerHeight() + F.window.h,
      })
      .setTween(tween)
      .addTo(F.controller);

    F.parallaxes.push(scene);
    F.tween.push(tween);

  }

  gallerySlider() {
    const target = $('.js-slider__gallery');
    const option = {
      autoplay: true,
      autoplaySpeed: 0, //待ち時間を０に
      speed: 5000, // スピードをゆっくり
      cssEase: 'linear', // 切り替えイージングを'linear'に
      // 以下、操作後に止まってしまう仕様の対策
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,
      arrows: false,
      centerMode: true,
      slidesToShow: 1,
      variableWidth: true,
    }

    target.slick(option);
  }

  beforeKv() {
    const target = $('.js-kv__max');
    const item = $('.js-slider__kv__item');

    target.find('img').attr('src', kvLogos[item.length]);
  }

  topSlider() {
    this.beforeKv();

    const target = $('.js-slider__kv');
    const time = 4000;
    const length = $('.js-slider__kv__item').length;

    const initial = Math.floor(Math.random() * length);

    const option = {
      slide: ".js-slider__kv__item",
      initialSlide: initial,
      arrows: false,
      autoplay: true,
      autoplaySpeed: time,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,
    }



    const current = $('.js-kv__current');
    const max = $('.js-kv__max');
    const timebar = $('.js-kv__timebar').find('i');

    const tween = TweenMax.fromTo(timebar, (time + 800) / 1000, {
      width: "0%",
      ease: Linear,
    }, {
      width: "100%",
      ease: Linear,
    });

    target.on("init", function(event, slick, currentSlide, nextSlide) {
      const currentImg = current.find('img');
      currentImg.attr('src', kvLogos[initial]);
      tween.restart();
    });

    target.slick(option);

    target.on("beforeChange", function(event, slick, currentSlide, nextSlide) {
      const currentImg = current.find('img');
      currentImg.attr('src', kvLogos[nextSlide]);
      tween.restart();
    });
  }
}

document.addEventListener("DOMContentLoaded", function(e) {
  fnc = new Fnc;
  fnc.ready();
});

$(window).on('load', function() {
  if (fnc === "undifined") {
    fnc = new Fnc();
    fnc.ready();
  }

  fnc.loaded();
});

$(window).resize(function() {
  fnc.resize();
});
